<template>
  <section class="reset">
    <div class="container-scroller full-page-style">
      <div class="container-fluid full-page-wrapper bg-default">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-dark text-left p-5">
                <div class="brand-logo">
                  <img src="../assets/images/logo.png">
                </div>
                <h4>{{ $t('resetPassword') }} </h4>
                <b-form @submit="reset">
                    <b-form-group :label="$t('password')" label-for="password">
                        <b-form-input v-model="password" id="password" type="password" :placeholder="$t('password')" :state="pwState" required 
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                          :title="$t('passwordCondition')" v-b-tooltip.top />
                        <b-form-input v-model="passwordConfirm" id="passwordConfirm" type="password" class="mt-2" :placeholder="$t('confirmPassword')" :state="pwState" required @input="onPwConfirmChange" />
                    </b-form-group>
                    <div class="text-danger" v-if="pwState === false">! {{ errorMessage }}</div>

                    <div class="mt-3">
                        <b-button type="submit" variant="primary" :disabled="processing" class="btn-block btn-lg font-weight-medium">{{ $t('resetPassword').toUpperCase() }}</b-button>
                    </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import axios from 'axios'

export default {
  name: 'reset',
  data(){
    return {
        password: '',
        passwordConfirm: '',
        errorMessage: '',
        pwState: null,
        processing: false,
    }
  },
  components: {
  },
  created() {
    axios.post('/api/Users/resetrequest', {
        Id: this.$route.query.request
      })
      .catch(error => {
          window.console.log(error)
          this.ShowErrorMessage(this.$t('expiredReset'));
          setTimeout(() => {this.$router.push({ path: '/'})}, 3000);
      });
  },
  methods: {
    init(){
      this.password = '';
      this.passwordConfirm = '';
      this.pwState = null;
      this.processing = false;
    },
    reset(event){
      event.preventDefault();
      if(this.pwConfirmValidate() && !this.processing){
        this.processing = true;
        var param = {
          Id: this.$route.query.request,
          Password: this.password,
        }
        axios.post('/api/Users/resetrequest', param)
        .then(response => {
          this.processing = false;
          this.ShowSuccessMessage(this.$t('resetComplete'));
          this.$router.push({ path: '/'});
          window.console.error(response)
        })
        .catch(error => {
          this.processing = false;
          this.$router.push({ path: '/'});
          window.console.error(error)
        });
      }
    },
    onPwConfirmChange(){
      this.pwState = null;
    },
    pwConfirmValidate(){
      if(this.password !== this.passwordConfirm) {
        this.pwState = false;
        this.errorMessage = this.$t('passwordNotMatched');
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss">
.input-bg-black {
  background-color: #2A3038 !important;
}
.custom-switch .custom-control-label::before {
    top: 1.46rem !important;
    cursor: pointer;
}
.custom-switch .custom-control-label::after {
    top: 1.6rem !important;
    cursor: pointer;
}
.linked {
  font-weight: bold;
  cursor: pointer;
  color: orange;
}
.full-page-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
.bg-default {
  background: #2e2f32;
}
</style>